import { romajiSkillLearningHistoriesGet } from '@/src/__generated__';
import { ErrorInvalidDateRange } from '@/src/components/HistoryQueryForm';
import {
  useHistoryQueryConditions,
  useSyncHistoryQueryConditions,
} from '@/src/hooks/useHistoryQueryConditions';
import { useAuthContext } from '@/src/middleware/auth/AuthContext';
import {
  LearningHistoryRomajiSkillQueryFormSchema,
  learningHistoryRomajiSkillQueryFormSchema,
} from '@/src/schema/learning-history-romaji-skill-query-form-schema';
import { DATE_RANGE_ERROR } from '@/src/schema/refinements/date-range';
import { zodResolver } from '@hookform/resolvers/zod';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { ReactNode, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { COLUMN_DEFS, RowData } from './columns';

export const useLearningHistoryRomajiSkill = () => {
  const { uuid, getCurrentUser } = useAuthContext();
  const { historyQueryConditions } = useHistoryQueryConditions();
  const [data, setData] = useState<RowData[]>([]);
  const [errorMessage, setErrorMessage] = useState<ReactNode>('');

  const table = useReactTable({
    columns: [
      COLUMN_DEFS.date,
      COLUMN_DEFS.total_number,
      COLUMN_DEFS.total_keystrokes,
      COLUMN_DEFS.average_keystroke_speed,
      COLUMN_DEFS.average_time,
    ],
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  const query = async (params: LearningHistoryRomajiSkillQueryFormSchema) => {
    const res = await romajiSkillLearningHistoriesGet({
      ...params,
      user_uuid: undefined,
      time_slot: Number.isInteger(params.time_slot) && params.time_slot !== null ? params.time_slot : undefined,
      from_date: params.from_date,
      to_date: params.to_date,
    })
    .then((res) => {
      if (res.status === 200) {
        setErrorMessage('');
        setData(res.data.learning_histories);
      }
    }).catch((error) => {
      if (error.response.data.message.includes('日付')) {
        setErrorMessage('終了日が開始日より前の日付になっています。');
      }
    });
  };

  const methods = useForm<LearningHistoryRomajiSkillQueryFormSchema>({
    defaultValues: historyQueryConditions,
    resolver: zodResolver(learningHistoryRomajiSkillQueryFormSchema),
  });
  useSyncHistoryQueryConditions(['from_date', 'to_date'], methods.watch);

  const [from_date] = methods.watch(['from_date'])
  useEffect(()=>{
    query(methods.getValues());
  },[from_date])

  const [to_date] = methods.watch(['to_date'])
  useEffect(()=>{
    query(methods.getValues());
  },[to_date])

  useEffect(() => {
    if (!uuid || !getCurrentUser) return;
    const user = getCurrentUser();
    user?.first_use_date_romaji_skill &&
      methods.setValue('from_date', new Date(user.first_use_date_romaji_skill));
    query(methods.getValues());
  }, [uuid]);

  useEffect(() => {
    if (methods.formState.errors.to_date?.message === DATE_RANGE_ERROR) {
      setErrorMessage(<ErrorInvalidDateRange />);
    }
    if (!Object.keys(methods.formState.errors).length) {
      setErrorMessage(undefined);
    }
  }, [methods.formState.errors]);

  return {
    table,
    hasLoaded: !!data.length,
    methods,
    onSubmit: methods.handleSubmit(query),
    errorMessage,
  };
};
