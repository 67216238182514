import {
  GradeClass,
  HistoricalDataForTest,
  LibsDomainModelInterfaceKanjiGetKanjiSkillTestLearningHistoriesResponse,
  TeachingUnitEntity,
} from '@/src/__generated__';
import { formatToDateTimeDisplayShort } from '@/src/helpers/date';
import { useAuthContext } from '@/src/middleware/auth/AuthContext';
import { ColumnDef, GroupColumnDef } from '@tanstack/react-table';
import clsx from 'clsx';
import styles from './styles.module.css';

export type RowData =
  LibsDomainModelInterfaceKanjiGetKanjiSkillTestLearningHistoriesResponse['learning_histories'][0]['details'][0];
export type SubRowData = {
  user_uuid: string;
  school_class: GradeClass;
  type: 'プレ\nテスト' | 'テスト' | 'まとめ';
  frequency_type: string;
  historical_date: string;
  results: [string];
  total: string;
};

export const COLUMN_DEFS: Record<string, ColumnDef<any>> = {
  school_class: {
    id: 'school_class',
    size: 100,
    meta: { tdRowSpan: 4, stickyLeft: 0 },
    header: '年組',
    cell: (cell) => {
      const value = cell.row.getParentRow()?.original?.school_class;
      return `${value?.grade}年${value?.class_name}組`;
    },
  },
  name: {
    id: 'name',
    size: 220,
    meta: { tdRowSpan: 4, stickyLeft: 100, align: 'left' },
    header: '氏名',
    cell: (cell) => {
      const { getUserFromUuid } = useAuthContext();
      const uuid = cell.row.getParentRow()?.original?.user_uuid;
      const user = uuid && getUserFromUuid?.(uuid);
      if (!user) return '';
      return `${user.user_name} さん`;
    },
  },
  type: {
    id: 'type',
    accessorKey: `0.type`,
    size: 95,
    meta: { tdRowSpan: 4, stickyLeft: 320 },
    header: 'テスト種別',
    cell: ({ row }) => {
      return <div className={clsx(styles.type)}>{row.original.type}</div>;
    },
  },
  frequency_type: {
    id: 'date',
    size: 209,
    meta: { stickyLeft: 415 },
    header: '実施日時',
    cell: ({ row }) => {
      const value = row.original;
      return (
        <div className={clsx(styles['date-column'])}>
          <div className={clsx(styles['frequncy-type'])}>
            {value.frequency_type}
          </div>
          <div className={clsx(styles.date)}>
            {value.historical_date === '-'
              ? '-'
              : formatToDateTimeDisplayShort(value.historical_date)}
          </div>
        </div>
      );
    },
  },
};

export const access_data_columns = (
  teaching_unit: TeachingUnitEntity,
  data: HistoricalDataForTest,
): GroupColumnDef<RowData> => ({
  id: `teaching_unit_${teaching_unit.teaching_unit_id}`,
  header: `${teaching_unit.teaching_unit_number} ${teaching_unit.teaching_unit_name}`,
  size: 135 + 160 + 70 * (data ? data.questions.length : 0),
  columns: [
    ...(data
      ? data.questions.map((q, i) => ({
          id: `${teaching_unit.teaching_unit_id}_${q.question_number}`,
          accessorKey: `results.${i}`,
          size: 70,
          header: q.word,
        })) || []
      : []),
    {
      id: `${teaching_unit.teaching_unit_id}_total`,
      accessorKey: `total`,
      size: 160,
      meta: { align: 'right' },
      header: '合計',
    },
  ],
});

export const generateColumns = (
  teaching_unit: TeachingUnitEntity,
  data: HistoricalDataForTest,
) => [
  COLUMN_DEFS.school_class,
  COLUMN_DEFS.name,
  COLUMN_DEFS.type,
  COLUMN_DEFS.frequency_type,
  access_data_columns(teaching_unit, data),
];
