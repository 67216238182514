import './style.css';

const FormSendComplete = () => {
  return (
    <div className="logout">
      <div className="overlap-wrapper-11">
        <div className="overlap-58">
          <div className="message-2">
            <div className="overlap-61">
              <div className="text-wrapper-327">送信が完了しました。</div>
              <div className="text-wrapper-327-2">自動返信メールをお送りします。</div>
              <div className='text-wrapper-327-3'>
                <p>自動返信メールが届かない場合、以下の可能性がございます。</p>
                <ul>
                  <li>メールアドレスが間違っている</li>
                  <li>迷惑メールフォルダに入っている</li>
                  <li>セキュリティブロックされている</li>
                </ul>
                <p>24時間以内にメールが届かない場合は、以下をお試しください。</p>
                <ul>
                  <li>【*tomosplus.jp】の受信を許可する</li>
                  <li>他のメールアドレスで申請する</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormSendComplete;
