import {
  DownloadButton,
  FormItemSelect,
  TeacherHistorySelect,
  styles as formStyles,
} from '@/src/components/HistoryQueryForm';
import { HistoryQueryFormTeacher } from '@/src/components/HistoryQueryForm/HistoryQueryFormTeacher';
import { TeacherFrame } from '@/src/components/layouts/TeacherFrame';
import { FormProvider } from 'react-hook-form';
import { useLearningHistoryCbt } from './hooks';
import styles from './styles.module.css';
import { themeAtom, profileAtom } from '@/src/components/ModalContents/modalAtom';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { useToppageTeachers } from '../TeacherTop/hooks';


type Props = {
  onThemeChange: (status: number) => void
}

const P = styled('p')(
  ({ theme }) => `
    color:var(${theme.textcolor})!important
  `
)

export const TeacherHistoryCbt = (props: Props): JSX.Element => {
  const { hasLoaded, categories, methods, onSubmit, translateError } = useLearningHistoryCbt();
  
  const { currentUser } = useToppageTeachers();
  const [themenum, setThemenum] = useRecoilState(themeAtom)
  const [profiles, setProfiles] = useRecoilState(profileAtom)

  useEffect(() => {
    if (currentUser) {
      if (!themenum) {
        if (currentUser.preference) {
          setThemenum(currentUser.preference.theme_color);
        }
      }
      const _profiles = {
        nick_name: profiles.nick_name ? profiles.nick_name : currentUser?.nick_name,
        profile_icon: profiles.profile_icon && profiles.profile_icon !== currentUser?.preference.profile_icon ? profiles.profile_icon : currentUser?.preference.profile_icon
      }
      if (
        _profiles.nick_name !== profiles.nick_name ||
        _profiles.profile_icon !== profiles.profile_icon
      ) {
        setProfiles(_profiles);
      }
    }
    
  }, [currentUser, profiles, themenum]);

  useEffect(() => {
    if (currentUser && typeof themenum === 'number') {
      props.onThemeChange(themenum);
    }
  }, [themenum, currentUser]);

  return (
    <TeacherFrame title="学習履歴 教師用">
      {hasLoaded && (
        <div className={styles.body}>
          <FormProvider {...methods}>
            <HistoryQueryFormTeacher
              onSubmit={onSubmit}
              states={{
                studentSelectDisabled: true,
                schoolClassSelectAllDisabled: true,
                dateRangeDisabled: true,
                timeSlotDisabled: true,
              }}
            >
              <div className={formStyles.row}>
                <TeacherHistorySelect />
                {categories.length > 0 && (
                  <>
                    <FormItemSelect<string>
                      className={styles.item}
                      name="cbt_category_id"
                      options={
                        [{ label: '未選択', value: '' }].concat(
                          categories.map((category) => ({
                            label: `${category.grade}年 ${category.categoryName}`,
                            value: category.id,
                          }))
                        )
                      }
                      noRuby
                    />
                    <DownloadButton
                      className={styles.button}
                      type="submit"
                      disabled={methods.formState.isSubmitting}
                    />
                  </>
                )}
              </div>
            </HistoryQueryFormTeacher>
          </FormProvider>
          <P className={styles.description}>
            きそのたしかめCBT 国語の学習履歴をExcelでダウンロードできます。
          </P>
          <div className={styles.warning}>
          {methods.formState.errors.class_index && (
              <p>{translateError(methods.formState.errors.class_index.message)}</p>
            )}
            {methods.formState.errors.cbt_category_id && (
              <p>{translateError(methods.formState.errors.cbt_category_id.message)}</p>
            )}
            {methods.formState.errors.root && (
              <p>{methods.formState.errors.root.message}</p>
            )}
          </div>
        </div>
      )}
    </TeacherFrame>
  );
};
