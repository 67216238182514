import { styles as formStyles } from '@/src/components/HistoryQueryForm';
import MainButton, { MainButtonProps } from '@/src/components/parts/MainButton';
import clsx from 'clsx';
import { ReactNode } from 'react';
import styled, { DefaultTheme } from 'styled-components';

export const WordListRanking = ({
  items,
  theme
}: {
  items: { rank: number; word: ReactNode }[];
  theme?: DefaultTheme
}) => (
  <WordList
    label={RANKING_LABEL}
    items={items.map(toRankingItem)}
    captionClassName='caption-ranking'
  />
);

export const WordListRandom = ({
  items,
  reload,
}: Pick<WordListProps, 'items'> & { reload: VoidFunction }) => (
  <WordList
    label={RANDOM_LABEL}
    items={items}
    control={
      <ReloadButton
        type="button"
        className={formStyles.submit}
        onClick={reload}
      />
    }
    captionClassName='caption-random'
  />
);

type WordListProps = {
  label: ReactNode;
  control?: ReactNode;
  items: {
    caption: ReactNode;
    word: ReactNode;
  }[];
  className?: string;
  captionClassName?: string;
  theme?: DefaultTheme;
};

const Wrap = styled('div')(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    .labelsm {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;    
      font-size: 20px;
      @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none)  {font-size: 1.25rem;}
      font-family: var(--main-font-family-db);
      letter-spacing: 0.05em;
      line-height: 25px;
    }
    ol {
      border-radius: 4px;
      border: 1px solid var(${theme.textcolor});
      overflow: hidden;
      li {
        height: 65px;
        display: flex;
        &:not(:last-child) {
          border-bottom: 1px solid var(${theme.textcolor});
        }
        > * {
          display: flex;
          align-items: flex-end;
          padding-bottom: 22px;
        }
        .caption {
          justify-content: center;
          height: 100%;
          border-right: 1px solid var(${theme.textcolor});
          background-color: var(${theme.maincolor});
          color: var(--white);
        
          font-size: 20px;
          @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none)  {font-size: 1.25rem;}
          font-family: var(--main-font-family-db);
          letter-spacing: 0.05em;
          line-height: 1;
        }
        .caption-ranking {
          width: 79px;
        }
        .caption-random {
          width: 170px;
          column-gap: 0.75em;
        }
        .contentls {
          width: 585px;
          height: 100%;
          padding-left: 20px;
          background-color: var(${theme.lightcolor});
        
          font-size: 25px;
          @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none)  {font-size: 1.5625rem;}
          font-family: var(--main-font-family-db);
          letter-spacing: 0.1em;
          line-height: 1;
        }
      }
    }
  `
)

const WordList = ({
  label,
  control,
  items,
  className,
  captionClassName,
  theme
}: WordListProps) => {
  return (
    <Wrap>
      <div className='labelsm'>
        <div>{label}</div>
        {control && <div> {control}</div>}
      </div>
      {items.length > 0 && (
        <ol>
          {items.map(({ caption, word }, index) => (
            <li key={index}>
              {captionClassName === 'caption-random' && (
                <>
                  <div className={`${clsx(captionClassName)} caption`}>
                    {caption !== undefined ? (
                      //@ts-ignore
                      <span dangerouslySetInnerHTML={{ __html: addRuby(caption) }} />
                    ) : (
                      <span>{caption}</span>
                    )}
                  </div>
                </>
              )}
              {captionClassName === 'caption-ranking' && (
                <div className={`${clsx(captionClassName)} caption`}>
                  <span>{caption}</span>
                </div>
              )}
              <div className='contentls'>{word}</div>
            </li>
          ))}
        </ol>
      )}
    </Wrap>
  );
};

export const LABEL = (
  <>
    みんなの
    <ruby>
      調<rt>しら</rt>
    </ruby>
    べた
    <ruby>
      言<rt>こと</rt>
    </ruby>
    <ruby>
      葉<rt>ば</rt>
    </ruby>
  </>
);

const ReloadButton = (props: Omit<MainButtonProps, 'children'>) => (
  <MainButton {...props}>
    <ruby>
      再<rt>さい</rt>
    </ruby>
    <ruby>
      表<rt>ひょう</rt>
    </ruby>
    <ruby>
      示<rt>じ</rt>
    </ruby>
  </MainButton>
);

const RANKING_LABEL = (
  <div>
    {LABEL}
    （ランキング）
  </div>
);

const toRankingItem = ({ rank, word }: { rank: number; word: ReactNode }) => ({
  caption: (
    <>
      {rank}
      <ruby>
        位<rt>い</rt>
      </ruby>
    </>
  ),
  word: <>{word}</>,
});

const RANDOM_LABEL = (
  <div>
    {LABEL}
    （ランダム）
  </div>
);

const addRuby = (text: ReactNode) => {
  const rubyPairs = [
    { kanji: '北海道', reading: 'ほっかいどう' },
    { kanji: '青森県', reading: 'あおもりけん' },
    { kanji: '岩手県', reading: 'いわてけん' },
    { kanji: '宮城県', reading: 'みやぎけん' },
    { kanji: '秋田県', reading: 'あきたけん' },
    { kanji: '山形県', reading: 'やまがたけん' },
    { kanji: '福島県', reading: 'ふくしまけん' },
    { kanji: '茨城県', reading: 'いばらぎけん' },
    { kanji: '栃木県', reading: 'とちぎけん' },
    { kanji: '群馬県', reading: 'ぐんまけん' },
    { kanji: '埼玉県', reading: 'さいたまけん' },
    { kanji: '千葉県', reading: 'ちばけん' },
    { kanji: '東京都', reading: 'とうきょうと' },
    { kanji: '神奈川県', reading: 'かながわけん' },
    { kanji: '新潟県', reading: 'にいがたけん' },
    { kanji: '富山県', reading: 'とやまけん' },
    { kanji: '石川県', reading: 'いしかわけん' },
    { kanji: '福井県', reading: 'ふくいけん' },
    { kanji: '山梨県', reading: 'やまなしけん' },
    { kanji: '長野県', reading: 'ながのけん' },
    { kanji: '岐阜県', reading: 'ぎふけん' },
    { kanji: '静岡県', reading: 'しずおかけん' },
    { kanji: '愛知県', reading: 'あいちけん' },
    { kanji: '三重県', reading: 'みえけん' },
    { kanji: '滋賀県', reading: 'しがけん' },
    { kanji: '京都府', reading: 'きょうとふ' },
    { kanji: '大阪府', reading: 'おおさかふ' },
    { kanji: '兵庫県', reading: 'ひょうごけん' },
    { kanji: '奈良県', reading: 'ならけん' },
    { kanji: '和歌山県', reading: 'わかやまけん' },
    { kanji: '鳥取県', reading: 'とっとりけん' },
    { kanji: '島根県', reading: 'しまねけん' },
    { kanji: '岡山県', reading: 'おかやまけん' },
    { kanji: '広島県', reading: 'ひろしまけん' },
    { kanji: '山口県', reading: 'やまぐちけん' },
    { kanji: '徳島県', reading: 'とくしまけん' },
    { kanji: '香川県', reading: 'かがわけん' },
    { kanji: '愛媛県', reading: 'えひめけん' },
    { kanji: '高知県', reading: 'こうちけん' },
    { kanji: '福岡県', reading: 'ふくおかけん' },
    { kanji: '佐賀県', reading: 'さがけん' },
    { kanji: '長崎県', reading: 'ながさきけん' },
    { kanji: '熊本県', reading: 'くまもとけん' },
    { kanji: '大分県', reading: 'おおいたけん' },
    { kanji: '宮崎県', reading: 'みやざきけん' },
    { kanji: '鹿児島県', reading: 'かごしまけん' },
    { kanji: '沖縄県', reading: 'おきなわけん' },
    { kanji: '年', reading: 'ねん' }
  ]

  let result = text?.toString();
  rubyPairs.forEach(({ kanji, reading }) => {
    const rubyTag = `<ruby>${kanji}<rt>${reading}</rt></ruby>`;
    result = result?.replace(new RegExp(kanji, 'g'), rubyTag);
  });

  return result;
}


