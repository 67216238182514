import { RomajiLearningHistory } from '@/src/__generated__';
import { formatToDateDisplay } from '@/src/helpers/date';
import { ColumnDef } from '@tanstack/react-table';

export type RowData = RomajiLearningHistory;

export const COLUMN_DEFS: Record<string, ColumnDef<RowData>> = {
  date: {
    accessorKey: 'date',
    size: 160,
    meta: { stickyLeft: 0 },
    cell: ({ cell }) => {
      const value = cell.getValue();
      if (!value) return;
      return formatToDateDisplay(value as any);
    },
    header: () => (
      <div>
        <ruby>
          日<rt>ひ</rt>
        </ruby>
        <ruby>
          付<rt>づけ</rt>
        </ruby>
      </div>
    ),
  },
  total_number: {
    accessorKey: 'total_number',
    size: 147,
    header: () => (
      <div>
        <ruby>
          取<rt>と</rt>
        </ruby>
        り
        <ruby>
          組<rt>く</rt>
        </ruby>
        んだ
        <br/>
        <ruby>
          問<rt>もん</rt>
        </ruby>
        <ruby>
          題<rt>だい</rt>
        </ruby>
        <ruby>
          数<rt>すう</rt>
        </ruby>
      </div>
    ),
  },
  total_keystrokes: {
    accessorKey: 'total_keystrokes',
    size: 147,
    header: () => (
      <div>
        <ruby>
          入<rt>にゅう</rt>
        </ruby>
        <ruby>
          力<rt>りょく</rt>
        </ruby>
        した
        <br/>
        <ruby>
          文<rt>も</rt>
        </ruby>
        <ruby>
          字<rt>じ</rt>
        </ruby>
        <ruby>
          数<rt>すう</rt>
        </ruby>
      </div>
    ),
  },
  average_keystroke_speed: {
    accessorKey: 'average_keystroke_speed',
    size: 213,
    header: () => (
      <div>
        <div>
          <ruby>
            平<rt>へい</rt>
          </ruby>
          <ruby>
            均<rt>きん</rt>
          </ruby>
          <ruby>
            入<rt>にゅう</rt>
          </ruby>
          <ruby>
            力<rt>りょく</rt>
          </ruby>
          スピード
        </div>
        <div>
          （
          <ruby>
            入<rt>にゅう</rt>
          </ruby>
          <ruby>
            力<rt>りょく</rt>
          </ruby>
          <ruby>
            数<rt>すう</rt>
          </ruby>
          ／
          <ruby>
            秒<rt>びょう</rt>
          </ruby>
          ）
        </div>
      </div>
    ),
  },
  average_time: {
    accessorKey: 'average_time',
    size: 917,
    columns: [
      {
        accessorKey: 'average_time_step1',
        header: 'ステップ1',
        meta: { align: 'right' },
      },
      {
        accessorKey: 'average_time_step2',
        header: 'ステップ2',
        meta: { align: 'right' },
      },
      {
        accessorKey: 'average_time_step3',
        header: 'ステップ3',
        meta: { align: 'right' },
      },
      {
        accessorKey: 'average_time_step4',
        header: 'ステップ4',
        meta: { align: 'right' },
      },
    ],
    header: () => (
      <div>
        <ruby>
          各<rt>かく</rt>
        </ruby>
        ステップにおける1
        <ruby>
          回<rt>かい</rt>
        </ruby>
        あたりの
        <ruby>
          平<rt>へい</rt>
        </ruby>
        <ruby>
          均<rt>きん</rt>
        </ruby>
        <ruby>
          時<rt>じ</rt>
        </ruby>
        <ruby>
          間<rt>かん</rt>
        </ruby>
        （
        <ruby>
          秒<rt>びょう</rt>
        </ruby>
        ）
      </div>
    ),
  },
};
