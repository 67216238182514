import { RomajiLearningHistory } from '@/src/__generated__';
import { formatToDateDisplay } from '@/src/helpers/date';
import { useAuthContext } from '@/src/middleware/auth/AuthContext';
import { CellContext, ColumnDef } from '@tanstack/react-table';

export type RowData = RomajiLearningHistory;

export const COLUMN_DEFS: Record<string, ColumnDef<RowData>> = {
  name: {
    id: 'name',
    accessorKey: 'user_uuid',
    size: 223,
    meta: { stickyLeft: 0, align: 'left' },
    header: '氏名',
    cell: (cell) => {
      const { getUserFromUuid } = useAuthContext();
      const uuid = cell.getValue() as string | undefined;
      if (uuid) {
        const user = uuid && getUserFromUuid?.(uuid);
        if (!user) return '';
        const userName = `${user.user_name} さん`;

        return (
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
            }}
            title={user.user_name} // Tooltip when hovering over the cell
          >
            {`${user.user_name} さん`}
          </div>
        );
      }
      const row = cell.row.original;
      if (!row.grade) return '全体';
      if (!row.class_name) return `${row.grade}年全体`;
      return `${row.grade}年${row.class_name}組`;
    },
  },
  date: {
    id: 'date',
    accessorKey: 'date',
    size: 170,
    header: '日付',
    cell: ({ cell }: CellContext<any, any>) => {
      const value = cell.getValue();
      if (!value) return;
      return formatToDateDisplay(value as any);
    }
  },
  total_number: {
    id: 'total_number',
    accessorKey: 'total_number',
    size: 155,
    header: () => (<div>取り組んだ<br />問題数</div>),
    meta: { align: 'right' },
  },
  total_keystrokes: {
    id: 'total_keystrokes',
    accessorKey: 'total_keystrokes',
    size: 127,
    header: () => (<div>入力した<br />文字数</div>),
    meta: { align: 'right' },
  },
  average_keystroke_speed: {
    id: 'average_keystroke_speed',
    accessorKey: 'average_keystroke_speed',
    size: 193,
    header: () => (<div>平均入力スピード<br />（入力数／1秒）</div>),
    meta: { align: 'right' },
  },
  average_time: {
    id: 'average_time',
    size: 577,
    header: () => (<div>各ステップにおける1回あたりの平均時間（秒）</div>),
    columns: [
      {
        accessorKey: 'average_time_step1',
        header: 'ステップ1',
        meta: { align: 'right' },
      },
      {
        accessorKey: 'average_time_step2',
        header: 'ステップ2',
        meta: { align: 'right' },
      },
      {
        accessorKey: 'average_time_step3',
        header: 'ステップ3',
        meta: { align: 'right' },
      },
      {
        accessorKey: 'average_time_step4',
        header: 'ステップ4',
        meta: { align: 'right' },
      },
    ],
  },
};
