import { z } from 'zod';

export const learningHistoryCbtQueryFormSchema = z.object({
  class_index: z.number().nullish().or(z.enum([""])),
  cbt_category_id: z.string().min(1,{ message: "選択してください" }).refine(value => value !== '', {
    message: "種別が選択されていません"
  }),
});

export type LearningHistoryCbtQueryFormSchema = z.infer<
  typeof learningHistoryCbtQueryFormSchema
>;
