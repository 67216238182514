import { Category, kanjiSkillLearningHistoriesGet } from '@/src/__generated__';
import { ErrorInvalidDateRange } from '@/src/components/HistoryQueryForm';
import {
  useHistoryQueryConditions,
  useSyncHistoryQueryConditions,
} from '@/src/hooks/useHistoryQueryConditions';
import { useAuthContext } from '@/src/middleware/auth/AuthContext';
import {
  LearningHistoryKanjiSkillQueryFormSchema,
  learningHistoryKanjiSkillQueryFormSchema,
} from '@/src/schema/learning-history-kanji-skill-query-form-schema';
import { DATE_RANGE_ERROR } from '@/src/schema/refinements/date-range';
import { KanjiApplicationType } from '@/src/types/KanjiApplicationType';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RowData, generateColumns } from './columns';
import { useRecoilState } from 'recoil';
import { errorMessageAtom } from '@/src/components/ModalContents/modalAtom';
import { get_category_index_from_type_id, get_category_selection, get_current_type_category_id, save_category_selection } from '@/src/helpers/kanji-skill/pulldown';
import { recentPastOf } from '@/src/helpers/date';

export const useLearningHistoryKanjiSkill = () => {
  const { uuid, getCurrentUser } = useAuthContext();
  const { historyQueryConditions } = useHistoryQueryConditions();
  const [data, setData] = useState<RowData[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [type, setType] = useState<KanjiApplicationType>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const columns = useMemo<ColumnDef<any>[]>(
    () => generateColumns(data[0]),
    [data],
  );
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  const [errorMessage, setErrorMessage] = useRecoilState(errorMessageAtom);
  // const [errorMessage, setErrorMessage] = useState<ReactNode>('');

  const methods = useForm<LearningHistoryKanjiSkillQueryFormSchema>({
    defaultValues: historyQueryConditions,
    resolver: zodResolver(learningHistoryKanjiSkillQueryFormSchema),
  });
  useSyncHistoryQueryConditions(['from_date', 'to_date'], methods.watch);

  const query = async ({
    class_index,
    category_index,
    ...params
  }: LearningHistoryKanjiSkillQueryFormSchema) => {
    const user = getCurrentUser?.();
    if (!user) return;
    const [type, category_id] = get_current_type_category_id(user, category_index, categories);
    const res = await kanjiSkillLearningHistoriesGet({
      ...params,
      grade: user.grade,
      user_uuid: undefined,
      time_slot: Number.isInteger(params.time_slot) && params.time_slot !== null ? params.time_slot : undefined,
      type,
      category_id,
      from_date: params.from_date,
      to_date: params.to_date,
    })
      .then((res) => {
        if (res.status === 200) {
          setErrorMessage('');
          const user_school_class = {
            grade: user.grade,
            class_name: user.class_name,
          };
          if (categories.length < 1) {
            setCategories(res.data.categories ? res.data.categories : []);
            if (res.data.category && res.data.category.category_id !== null) {
              category_index = get_category_index_from_type_id(res.data.category.type, res.data.category.category_id, res.data.categories);
            }
            else {
              category_index = get_category_index_from_type_id(type, category_id, res.data.categories);
            }
            methods.setValue("category_index", category_index);
          }
          if (res.data.category) {
            save_category_selection(category_index, res.data.category.type, res.data.category.category_id);
            setType(res.data.category.type);
          }
          else {
            save_category_selection(category_index, type, category_id);
            setType(type);
          }
          setData(
            appendTotal(
              res.data.daily_data.map((d) => ({
                user_uuid: user.user_uuid,
                school_class: user_school_class,
                daily_datum: d,
              })),
            ),
          );
        }
      })
      .catch((error) => {
        if (error.response.data.message.includes('日付')) {
          setErrorMessage('終了日が開始日より前の日付になっています。');
        }
      });
  };

  const [category_id, from_date, to_date] = methods.watch([
    'category_index',
    'from_date',
    'to_date',
  ]);
  useEffect(() => {
    if (loaded) {
      methods.clearErrors();
      query(methods.getValues());
    }
  }, [category_id, from_date, to_date, loaded]);

  useEffect(() => {
    if (!uuid || !getCurrentUser) return;
    const user = getCurrentUser();
    if (!user) return;

    // historyQueryConditions.from_date が基準日を超えているかを確認
    const referenceDate = recentPastOf(4, 1);
    if (historyQueryConditions.from_date > referenceDate) {
      methods.setValue('from_date', historyQueryConditions.from_date);
    } else if (user.first_use_date_kanji_skill) {
      // historyQueryConditions.from_date が基準日以前の場合は user.first_use_date_kanji_skill を使用
      methods.setValue('from_date', new Date(user.first_use_date_kanji_skill));
    }
    // セッションストレージから category_index を取得（存在しない場合はデフォルト値 '0' を使用）
    const storedCategorySelection = get_category_selection();
    const category_index = parseInt(storedCategorySelection.category_index || 0);
    methods.setValue('category_index', category_index);
    setLoaded(true);
  }, [uuid]);

  useEffect(() => {
    if (methods.formState.errors.to_date?.message === DATE_RANGE_ERROR) {
      setErrorMessage(<ErrorInvalidDateRange />);
    }
    if (!Object.keys(methods.formState.errors).length) {
      setErrorMessage(undefined);
    }
  }, [methods.formState.errors]);

  return {
    table,
    hasLoaded: !!data.length,
    methods,
    onSubmit: methods.handleSubmit(query),
    errorMessage,
    conditions: {
      categories,
    },
    type,
  };
};

const appendTotal = (data: RowData[] = []) => {
  const total = data.reduce<RowData>(
    (acc, d) => {
      acc.daily_datum!.total_number! += d.daily_datum?.total_number || 0;
      d.daily_datum?.access_data?.forEach((a, i) => {
        if (!acc.daily_datum!.access_data![i]) {
          acc.daily_datum!.access_data![i] = {
            teaching_unit_number: a.teaching_unit_number,
            teaching_unit_name: a.teaching_unit_name,
            teaching_unit_code: a.teaching_unit_code,
            teaching_unit_type: a.teaching_unit_type,
            number_studies: 0,
          };
        }
        acc.daily_datum!.access_data![i].number_studies! += a.number_studies;
      });
      return acc;
    },
    {
      daily_datum: { total_number: 0, access_data: [], date: undefined },
      isTotal: true,
    },
  );
  return [...data, total];
};

