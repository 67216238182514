import ButtonAppleLogin from '@/src/components/auth/ButtonAppleLogin';
import ButtonGoogleLogin from '@/src/components/auth/ButtonGoogleLogin';
import ButtonMicrosoftLogin from '@/src/components/auth/ButtonMicrosoftLogin';
import InputBox from '@/src/components/auth/InputBox';
import Kanji from '@/src/components/auth/Kanji';
import RouteLink from '@/src/components/auth/Link';
import SecondaryButton from '@/src/components/parts/SecondaryButton';
import { authConfig, useAuthContext } from '@/src/middleware/auth/AuthContext';
import { LoginClickEvent } from '@/src/middleware/auth/AuthContext';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TwentyTwo } from '../../../icons/TwentyTwo';
import useLoginForm from '@/src/hooks/useLoginForm';
import { LoginFormSchema } from '@/src/schema/login-form-schema';
import './style.css';
import { IconButton } from '@mui/material';
import { PostLoginHook } from '@/src/middleware/auth/AuthContext/AuthTypes';

export const Login = (): JSX.Element => {
  const {
    form: { control, handleSubmit, setValue, errors },
    options: { },
  } = useLoginForm();

  const [errorMsg, setErrorMsg] = useState<string | JSX.Element | undefined>('');

  const [isRevealPassword, setIsRevealPassword] = useState(false);

  console.log(errors);

  useEffect(() => {
    console.log(errors);
    let msg = '';
    if (errors.email?.message) {
      msg += errors.email?.message;
    }
    if (errors.password?.message) {
      if (msg) {
        msg += ' ';
      }
      msg += errors.password?.message;
    }
    setErrorMsg(msg);
  }, [errors]);

  const {
    PasswordLoginSubmit,
    SSOLoginGoogleClick,
    SSOLoginMicrosoftClick,
    SSOLoginAppleClick,
    hasLoaded,
    processLogout
  } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();

  const path =
    'state' in location && location.state && 'path' in location.state
      ? location.state.path
      : null;
  const initialEmail =
    'state' in location && location.state && 'email' in location.state
      ? location.state.email
      : '';

  useEffect(() => {
    setValue("email", initialEmail);
  }, [])

  const togglePassword = () => {
    setIsRevealPassword((prevState: boolean) => !prevState);
  };

  const [errorStatus, setErrorStatus] = useState('');
  const [errorCount, setErrorCount] = useState(0);

  const [isWait, setIsWait] = useState(true);

  const ERROR_SSO_ACCOUNT = 'sso_account';
  const ERROR_PASS_WRONG = 'pass_wrong';
  const ERROR_PASS_OVER_COUNT = 'pass_over_count';

  const LICENSE_STATUS_UNAVAILABLE = 0;
  const LICENSE_STATUS_READ_ONLY = 1;
  const LICENSE_STATUS_AVAILABLE = 2;

  const ERROR_MESSAGE_LOGIN_ONLY = "ライセンスの<ruby>有<rt>ゆう</rt></ruby><ruby>効<rt>こう</rt></ruby><ruby>期<rt>き</rt></ruby><ruby>限<rt>げん</rt></ruby>が<ruby>過<rt>す</rt></ruby>ぎてます。"
  const ERROR_MESSAGE_UNAVAILABLE = <><ruby>有<rt>ゆう</rt></ruby><ruby>効<rt>こう</rt></ruby>な<ruby>学<rt>がく</rt></ruby><ruby>習<rt>しゅう</rt></ruby>アプリのライセンスがありません。</>
  const ERROR_MESSAGE_EXPIRED = <>ログイン<ruby>可<rt>か</rt></ruby><ruby>能<rt>のう</rt></ruby><ruby>期<rt>き</rt></ruby><ruby>間<rt>かん</rt></ruby>を<ruby>過<rt>す</rt></ruby>ぎているため、ログインできません。</>

  const checkLicense: PostLoginHook = (loginUser, postLoginRedirect) => {
    const licenses = loginUser.license;
    if (licenses.length == 0) {
      // ライセンスなし
      processLogout!().then(() => {
        console.log("logout processed");
      });
      setErrorMsg(ERROR_MESSAGE_UNAVAILABLE);
      setIsWait(true);
      return false;
    }
    const active = licenses.filter((l) => l.status == LICENSE_STATUS_AVAILABLE);
    const readonly = licenses.filter((l) => l.status == LICENSE_STATUS_READ_ONLY);
    const expired = licenses.filter((l) => l.status == LICENSE_STATUS_UNAVAILABLE);
    if (active.length > 0) {
      // ライセンス有効
      postLoginRedirect(loginUser);
      return true;
    }
    if (readonly.length > 0) {
      // アプリの有効期限切れ ログインは可能
      navigate('/error', {
        state: { errorMessage: ERROR_MESSAGE_LOGIN_ONLY, showButton: true },
      });
      return true;
    }
    processLogout!().then(() => {
      console.log("logout processed");
    });
    if (expired.length > 0) {
      // ログイン可能時間切れ
      setErrorMsg(ERROR_MESSAGE_EXPIRED);
      setIsWait(true);
      return false;
    }
    // 有効なライセンスなし（本来到達しない）
    setErrorMsg(ERROR_MESSAGE_UNAVAILABLE);
    setIsWait(true);
    return false;
  }
  authConfig.postLoginHook = checkLicense;

  const LoginSubmit = async ({ email, password }: LoginFormSchema) => {
    // ログイン処理中の表示
    setIsWait(false);
    console.log([email, password]);
    setErrorStatus('');
    setErrorMsg('');
    if (email == undefined || password == undefined) {
      return;
    }
    if (PasswordLoginSubmit) {
      PasswordLoginSubmit(email, password, path).then((status: number | undefined) => {
        if (status != 200) {
          setIsWait(true);
        }
        console.log('submit result');
        console.log(status);

        if (status == 429) {
          setErrorStatus(ERROR_PASS_OVER_COUNT);
        }
        if (status == 401) {
          setErrorCount(errorCount + 1);
          if (errorCount > 5) {
            // setErrorStatus(ERROR_PASS_OVER_COUNT);　一旦フロントでのロックは実装しない
            setErrorStatus(ERROR_PASS_WRONG);
          }
          else {
            setErrorStatus(ERROR_PASS_WRONG);
          }
        }
      });
    }

  };

  const SSOSignIn = (action: LoginClickEvent | undefined) => {
    // ログイン処理中の表示
    if (action) {
      setIsWait(false);
      setErrorStatus('');
      setErrorMsg('');
      action().then((status: number | undefined) => {
        if (status != 200) {
          setIsWait(true);
        }
        console.log('submit result');
        console.log(status);
        //エラー処理
        if (status != 200 && status != 499) {
          setErrorStatus(ERROR_SSO_ACCOUNT);
        }
      }).catch((e) => {
        console.log("SSO error");
        console.log(e);
        setIsWait(true);
      }) ;
    }
  }
  const GoogleSignIn = (_event: any) => {
    return SSOSignIn(SSOLoginGoogleClick);
  }
  const AppleSignIn = (_event: any) => {
    return SSOSignIn(SSOLoginAppleClick);
  }
  const MicrosoftSignIn = (_event: any) => {
    return SSOSignIn(SSOLoginMicrosoftClick);
  }
  const loadingPage = () => (
    <>
      <div className="page-login">
        <img src="/img/type_d.gif" alt="読み込み中" className="loading" />
      </div>
    </>
  );
  const loginPage = () => (
    <>
      {isWait ? (
        <div className="page-login">
          <div className="panel-left">
            {false ? (
              <div className="btn-back">
                <div className="view">
                  <div className="overlap-group-3">
                    <div className="text-wrapper-7">前の画面にもどる</div>
                  </div>
                </div>
                <div className="text-wrapper-8">まえ</div>
                <div className="text-wrapper-9">が</div>
                <div className="text-wrapper-10">めん</div>
              </div>
            ) : (<></>) /* TODO 一時的に非表示  */}
            <TwentyTwo className="image" />
          </div>
          <div className="panel-right">
            <div className="sso-login-wrap">
              <ButtonGoogleLogin
                sso_icon="/img/auth/google-logo.png"
                onClick={GoogleSignIn}
              >
                Googleでサインイン
              </ButtonGoogleLogin>
              <ButtonMicrosoftLogin
                sso_icon="/img/auth/microsoft-logo.png"
                onClick={MicrosoftSignIn}
              >
                Microsoftでサインイン
              </ButtonMicrosoftLogin>
              <ButtonAppleLogin
                sso_icon="/img/auth/apple-wt-logo.png"
                onClick={AppleSignIn}
              >
                Appleでサインイン
              </ButtonAppleLogin>
              <div className="area-message">
                <div className="message">
                  ※<Kanji kana="がっ">学</Kanji>
                  <Kanji kana="こう">校</Kanji>で<Kanji kana="し">使</Kanji>
                  <Kanji kana="よう">用</Kanji>
                  しているアカウントでログインしてください。
                </div>
                {errorStatus == ERROR_SSO_ACCOUNT ? (
                  <>
                    <div className="warning message">
                      ※<Kanji kana="とう">登</Kanji>
                      <Kanji kana="ろく">録</Kanji>されていないアカウントです。
                    </div>
                    <div className="warning message">
                      <Kanji kana="がっ">学</Kanji>
                      <Kanji kana="こう">校</Kanji>で<Kanji kana="し">使</Kanji>
                      <Kanji kana="よう">用</Kanji>
                      しているアカウントでログインしてください。
                    </div>
                  </>)
                  : (<></>)
                }
              </div>
            </div>
            <div className="separator">
              <div className="line" />
              <div className="label">または</div>
              <div className="line" />
            </div>
            <div className="email-login-wrap">
              <div className="label-email-login">
                <div className="email-logo">
                  <img
                    className="email-logo-img"
                    alt="Group"
                    src="/img/group-10762.png"
                  />
                </div>
                メールアドレスでログイン
              </div>
              <form onSubmit={handleSubmit(LoginSubmit)}>
                <div className="area-input">
                  <div className="area-input-left">
                    <InputBox control={control} name="email" className="email" type="email" placeholder="メールアドレス" inputProps={{ maxLength: 256, pattern: "^[a-zA-Z0-9_\\.@\\-!#$%&'\\*\\+\\/=?^`\\{\\|\\}~]+$" }} />
                    <InputBox control={control} name="password" className="password" type={isRevealPassword ? 'text' : 'password'} placeholder="パスワード" />
                    <IconButton className="icon-eye" onClick={togglePassword}>
                      <img alt="Eye icon" src={isRevealPassword ? "/img/eyeicon_close.png" : "/img/eyeicon.png"} />
                    </IconButton>
                  </div>
                  <div className="area-input-right">
                    <SecondaryButton type="submit" disabled={errorStatus == ERROR_PASS_OVER_COUNT ? true : false}>
                      ログイン
                    </SecondaryButton>
                    <RouteLink className="forgot" href="/passwordreseturlsend" />
                  </div>
                </div>
              </form>
              <div className="area-input-message">
                {errorMsg != '' ? (
                  <div className="warning message">{errorMsg}</div>
                ) : (<></>)}
                {errorStatus == ERROR_PASS_WRONG ? (
                  <div className="warning message">※メールアドレスまたはパスワードがまちがっています。</div>
                ) :
                  errorStatus == ERROR_PASS_OVER_COUNT ? (
                    <div className="warning message">
                      ※パスワードを<ruby>一<rt>いっ</rt></ruby><ruby>定<rt>てい</rt></ruby><ruby>数<rt>すう</rt></ruby>まちがえたため、アカウントをロックしました<br />
                      <ruby>先<rt>せん</rt></ruby><ruby>生<rt>せい</rt></ruby>に<ruby>連<rt>れん</rt></ruby><ruby>絡<rt>らく</rt></ruby>してロックを<ruby>解<rt>かい</rt></ruby><ruby>除<rt>じょ</rt></ruby>してもらい、<ruby className="narrow-ruby">新<rt>あたら</rt></ruby>しいパスワードを<ruby>設<rt>せっ</rt></ruby><ruby>定<rt>てい</rt></ruby>してください
                    </div>
                  ) :
                    <></>
                }
              </div>
            </div>
          </div>
        </div>
      ) : (
        loadingPage()
      )}
    </>
  );

  return (
    <>
      {(hasLoaded && hasLoaded()) ? loginPage() : loadingPage()}
    </>
  );

};
