import dayGridPlugin from '@fullcalendar/daygrid';
import mkCalendarPlugin from '@/src/helpers/fullcalendar-holiday';
import FullCalendar from '@fullcalendar/react';
import './StudentTopCalender.css';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { DatesSetArg, EventInput } from '@fullcalendar/core';

type Props = {
  setCalendarRef?: React.RefObject<FullCalendar>;
  view?: string;
  date?: Date;
  initialView?: string;
  theme: any;
  data?: any;
  onChange: (arg: any) => void;
};

const GroupWrap = styled.div<{ theme: any }>`
  border: 1px solid;
  border-color: var(${(props) => props.theme.maincolor});
  background-color: var(${(props) => props.theme.lightcolor});
  border-radius: 16px;
  width: 478px;
  height: 534px;
  position: relative;
  margin-top: 11px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.4);
  .fc-group40 {
    height: 90px;
    left: 0;
    position: relative;
    top: -11px;
    width: 480px;
    .overlap-6 {
      height: 90px;
      position: relative;
      width: 478px;
      .text-wrapper-28 {
        color: var(--white);
        font-family: var(--main-font-family-db);
        letter-spacing: 1.2px;
        text-align: center;
        width: 370px;
        font-size: 24px;
        @media only screen and (min-device-width: 810px) and (max-device-width: 1080px), (any-hover: none) {
          font-size: 1.5rem;
        }
        line-height: 36px;
        position: absolute;
        top: 30px;
        left: 54px;
      }
    }
    .rectangle-2 {
      background-color: var(${(props) => props.theme.maincolor});
      border-radius: 16px 16px 0px 0px;
      height: 79px;
      left: 0;
      position: absolute;
      top: 11px;
      width: 478px;
    }
  }
  .fc-day {
    color: var(${(props) => props.theme.textcolor});
  }
  .fc-toolbar-title {
    color: var(${(props) => props.theme.textcolor});
  }
  .fc-icon {
    color: var(${(props) => props.theme.textcolor});
  }
  .stitle {
    font-size: 1.56em;
    position: absolute;
    top: 87px;
    left: 0;
    right: 0;
    margin: auto;
    width: 169px;
    text-align: center;
    color: var(${(props) => props.theme.textcolor});
  }
  .wk {
    font-size: 0.75em;
    position: absolute;
    top: 138px;
    @media only screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait),
      (min-width: 1366px) and (max-width: 1024px) and (orientation: landscape) {
      top: 127px;
    }
    @media only screen and (min-width: 810px) and (max-width: 1080px) {
      top: 129px;
    }

    color: ${(props) => props.theme.textcolor};
  }
  .wk.mon {
    left: 33px;
  }
  .wk.tue {
    left: 103px;
  }
  .wk.wed {
    left: 162px;
  }
  .wk.thu {
    left: 228px;
  }
  .wk.fri {
    left: 293px;
  }
  .wk.sat {
    left: 365px;
    color: var(--light-blue);
  }
  .wk.sun {
    left: 424px;
    color: var(--tomopla-color);
  }
`;

const dayHeaderWithRuby = (dayHeader: any) => {
  // 曜日名に対応するルビを定義
  const rubyDays:any = {
    '日': 'にち',
    '月': 'げつ',
    '火': 'か',
    '水': 'すい',
    '木': 'もく',
    '金': 'きん',
    '土': 'ど'
  };

  const dayText = dayHeader.text;
  const ruby = rubyDays[dayText] || '';

  return (
    <ruby>
      {dayText}
      <rt>{ruby}</rt>
    </ruby>
  );
};

const StudentTopCalender = ({
  data,
  onChange,
}: Props) => {
  const [events, setEvents] = useState<EventInput[]>([]);
  const [title, setTitle] = useState<string>('');
  const medalType = ['bronze', 'silver', 'gold'];

  useEffect(() => {
    setEvents(
      data?.reduce((acc: EventInput[], d: any) => {
        d.date_data.forEach((e: any) => {
          acc.push({
            start: new Date(d.year, d.month - 1, e.day),
            className: medalType[e.medal_type - 1],
          });
        });
        return acc;
      }, [])
    );

    const dom1 = document.querySelector('.fc-toolbar-title');
    if (dom1) {
      const [year, month] = dom1.innerHTML.split('年')[0].split('/');
      if (year && month) {
        setTitle(`${year}<ruby>年<rt>ねん</rt></ruby>${month}<ruby>月<rt>がつ</rt></ruby>`);
      }
    }
  }, [data]);

  const eventDidMount = (e: { el: HTMLElement; view: { type: string } }) => {
    const el = e.el;
    const dayElement = el.closest('.fc-daygrid-day');
    if (dayElement && e.view.type === 'dayGridMonth') {
      if (el.classList.contains('holiday-event')) {
        dayElement.classList.add('is_holiday');
      }
      if (el.classList.contains('silver')) {
        dayElement.classList.add('medal-silver');
      }
      if (el.classList.contains('bronze')) {
        dayElement.classList.add('medal-bronze');
      }
      if (el.classList.contains('gold')) {
        dayElement.classList.add('medal-gold');
      }
    }
  };

  // Medal images preloading to prevent initial load issues
  useEffect(() => {
    medalType.forEach((type) => {
      const img = new Image();
      img.src = `/img/medal-${type}.svg`;
    });
  }, [medalType]);

  return (
    <>
      <GroupWrap className="fc">
        <div className="fc-group40">
          <div className="overlap-6">
            <div className="rectangle-2" />
            <div className="text-wrapper-28">ログインカレンダー</div>
          </div>
        </div>
        <FullCalendar
          plugins={[dayGridPlugin, mkCalendarPlugin]}
          initialView="dayGridMonth"
          weekends={true}
          events={events}
          eventDidMount={eventDidMount}
          datesSet={(arg: DatesSetArg) => onChange(arg.start)}
          firstDay={1}
          titleFormat={{ year: 'numeric', month: 'numeric' }}
          locale="ja"
          dayHeaderContent={dayHeaderWithRuby}
          dayCellContent={(event: any) => (event.dayNumberText = event.dayNumberText.replace('日', ''))}
          eventClick={(info) => info.jsEvent.preventDefault()}
        />
        <div className="stitle" dangerouslySetInnerHTML={{ __html: title }}></div>
      </GroupWrap>
    </>
  );
};

export default StudentTopCalender;
