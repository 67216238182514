import { z } from 'zod';
import { dateRangeRefinement } from './refinements/date-range';

export const learningHistoryMovieQueryFormSchema = z
  .object({
    grade: z.number().nullish(),
    class_index: z.number().nullish().or(z.enum([""])),
    from_date: z.date(),
    to_date: z.date(),
  })
  .superRefine(dateRangeRefinement);

export type LearningHistoryMovieQueryFormSchema = z.infer<
  typeof learningHistoryMovieQueryFormSchema
>;
