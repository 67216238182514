import clsx from 'clsx';
import styles from './styles.module.css';
import styled from 'styled-components';
import Kanji from '../auth/Kanji';

export type NotificationProps = {
  html: string | null | undefined;
  className?: string;
  theme?: any;
};

const NotificationWrap = styled('div')(
  ({ theme }) => `
    display: flex;
    width: 100%;
    height: 107px;
    background: var(${theme.maincolor});
    padding: 3px;
    border-radius: 19px;    
    font-family: var(--main-font-family-r);
    
  `
)
const ItemsContainer = styled('div')(
  ({ theme }) => `
    flex: 1;
    height: 100%;
    padding: 14px 15px 13px;
    border-radius: 0 16px 16px 0;
    background: var(${theme.lightcolor});
    div {
      height: 100%;
      overflow-x: auto;
      color: var(${theme.textcolor});
      font-size: 20px;
      @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.25rem;};
      line-height: 36px;
      a {
        text-decoration:underline;
        text-underline-offset: 4px;
      }
      b {
        font-family: var(--main-font-family-db)!important;
      }
    }
    div * {
      font-size: 20px;
      @media only screen and (min-device-width: 810px) and (max-device-width: 1080px) ,(any-hover:none) {font-size: 1.25rem;};
      
      font-family: var(--main-font-family-m);
      letter-spacing: 0.05em;
      line-height: 36px;
      
    }
    div::-webkit-scrollbar {
      background-color: var(--gray);
      width: 12px;
      border-radius: 20px;
    }
    div::-webkit-scrollbar-thumb {
      background-color: var(--dark-gray-2);
      border-radius: 20px;
    }
  `
)

export const Notification = ({ className, html, theme }: NotificationProps) => {

  return (
    <NotificationWrap>
      <div className={styles.icon}>
        <img src="/img/bell.png" />
        <div className={styles.row}>
          お<ruby>知<rt>し</rt></ruby>らせ
        </div>
      </div>
      <ItemsContainer>
        <div dangerouslySetInnerHTML={{ __html: html || '' }} />
      </ItemsContainer>
    </NotificationWrap>
  );
};
