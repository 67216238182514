import { SchoolClassSelect } from '@/src/components/HistoryQueryForm';
import { InputSearch } from '@/src/components/InputSearch';
import { TableWithScroll } from '@/src/components/Table';
import { TeacherFrame } from '@/src/components/layouts/TeacherFrame';
import { ChildDetailModal } from './ChildDetailModal';
import { useStudentList } from './hooks';

import styles from './styles.module.css';
import clsx from 'clsx';
import { themeAtom, profileAtom } from '@/src/components/ModalContents/modalAtom';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { useToppageTeachers } from '../TeacherTop/hooks';

type Props = {
  onThemeChange: (status: number) => void;
}

const StdTable = styled.div<{ theme: { background: string; textcolor: string } }>`
  .stdlist {
    table {
      tbody {
        tr:last-child {
          td {
            background-color: ${({ theme }) => `var(${theme.background})`};
            color: ${({ theme }) => `var(${theme.textcolor})`};
          }
        }
        td:last-child {
          color: ${({ theme }) => `var(${theme.textcolor})`};
        }
      }
    }
  }
`;

export const TeacherStudentList = ({ onThemeChange }: Props) => {
  const {
    hasLoaded,
    table,
    schoolClassList,
    historyQueryConditions: { class_index, studentName },
    updateHistoryQueryConditions,
    selectedChild,
    setSelectedChild,
  } = useStudentList();

  const { currentUser } = useToppageTeachers();
  const [themeNum, setThemeNum] = useRecoilState(themeAtom);
  const [profiles, setProfiles] = useRecoilState(profileAtom);

  useEffect(() => {
    if (currentUser) {
      setThemeNum((prev) => prev || currentUser.preference.theme_color);
      
      setProfiles((prev) => ({
        nick_name: prev.nick_name || currentUser.nick_name,
        profile_icon: prev.profile_icon !== currentUser.preference.profile_icon
          ? prev.profile_icon
          : currentUser.preference.profile_icon,
      }));
    }
    
    return () => {
      updateHistoryQueryConditions({ studentName: null });
    };
  }, [currentUser, setProfiles, setThemeNum]);

  useEffect(() => {
    if (currentUser && typeof themeNum === 'number') {
      onThemeChange(themeNum);
    }
  }, [themeNum, currentUser, onThemeChange]);

  return (
    <TeacherFrame title="児童情報一覧 教師用">
      {hasLoaded && (
        <StdTable className={styles.body}>
          <div className={styles.conditions}>
            <SchoolClassSelect
              schoolClassList={schoolClassList}
              value={class_index}
              onChange={(value) =>
                updateHistoryQueryConditions({ class_index: value })
              }
            />
            <InputSearch
              placeholder="児童名検索"
              value={studentName || undefined}
              onChange={({ target: { value } }) =>
                updateHistoryQueryConditions({ studentName: value })
              }
            />
          </div>
          <TableWithScroll
            className={clsx('stdlist', styles.table)}
            table={table}
            maxWidth={1480}
            maxHeight={864}
          />
        </StdTable>
      )}
      <ChildDetailModal
        child={selectedChild}
        open={!!selectedChild}
        close={() => setSelectedChild(undefined)}
      />
    </TeacherFrame>
  );
};