import FormSendComplete from '@/src/components/FormSendComplete/FormSendComplete';
import { RHFSelect } from '@/src/components/RHFSelect';
import { RHFSelectMunicipality } from '@/src/components/RHFSelectMunicipality';
import { RHFSelectSchool } from '@/src/components/RHFSelectSchool';
import { RHFTextField } from '@/src/components/RHFTextField';
import MainButton from '@/src/components/parts/MainButton';
import SubButton from '@/src/components/parts/SubButton';
import useLicenseApplyForm from '@/src/hooks/useLicenseApplyForm';
import { Fifteen } from '../../icons/Fifteen';
import './style.css';


export const LicenseApplyForm = (): JSX.Element => {
  const {
    form: {
      control,
      handleSubmit,
      onSubmit,
      errors,
      isConfirm,
      sortedErrors,
      handleEdit,
      handleMailSendPost,
      selectChange,
      prefectureSelectChange,
      manicipalitySelectChange,
      isSchoolAltDisabled,
      isSubmissionComplete,
      setValue,
    },
    options: {
      options,
      optionsWithPrefectures,
      optionWithMunicipalities,
      optionWithSchoolNames,
    },
  } = useLicenseApplyForm();

  console.log(isSchoolAltDisabled, 'isSchoolAltDisabled');

  if (isSubmissionComplete) {
    return <FormSendComplete />;
  }
  console.log('form error', errors);

  return (
    <div className="license-apply-form">
      <div className="overlap-wrapper-13">
        <div className="overlap-64">
          <div className="no-BG-2">
            <div className="group-203">
              <div className="frame-32">
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
              </div>
            </div>
            <div className="group-204">
              <div className="frame-32">
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
              </div>
            </div>
            <div className="group-205">
              <div className="frame-32">
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
              </div>
            </div>
            <div className="group-206">
              <div className="frame-32">
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
              </div>
            </div>
            <div className="group-207">
              <div className="frame-32">
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
                <div className="frame-33">
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                  <div className="ellipse-22" />
                </div>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="title-7">
              <div className="view-58">
                <div className="group-226">
                  <div className="text-wrapper-359">
                    紙付録 ライセンス申請フォーム
                  </div>
                  <Fifteen className="instance-14" />
                </div>
              </div>
            </div>
            <div className="message-description-3">
              {!isConfirm ? (
                <>
                  ユーザー登録をしてデジタルコンテンツをご利用になりたい場合は、
                  <br />
                  下記フォームに必要事項をご入力の上、ライセンス発行の申請を行ってください。
                </>
              ) : (
                <>
                  入力内容を確認して、問題がなければ「申請する」ボタンを押してください。
                  <br />
                  間違いがある場合は「修正する」を押して内容を修正してください。
                </>
              )}
            </div>

            <div className='message-description-4'>
              <div style={{fontWeight:'bold',fontSize:'1.4rem'}}>ライセンスを申請するにあたって</div>
              <ul>
                <li>アカウントを登録して学習履歴を保存・確認したい場合に、ライセンスを申請してください。<br/>
                アカウント登録が必要ない場合は、ライセンスの申請は不要です。</li>
                <li>中学校教材や、「購入教材名」プルダウンに選択肢のない教材を採用されている場合は、ライセンスを申請することはできません。</li>
                <li>誠に申し訳ございませんが、現時点で海外の学校様には対応しておりません。解除コードによるアカウント登録なしのご利用をご検討ください。</li>
              </ul>
            </div>

            <div className="main-form">
              <div className="error-messages">
                {sortedErrors.map(({ fieldName, message }) => (
                  <div key={fieldName} className="error-message">
                    {message}
                  </div>
                ))}
              </div>
              <div className="block-school">
                <div className="input-group-school">
                  <div className="select-school-prefecture">
                    <RHFSelect
                      name="prefecture_name"
                      label="都道府県"
                      control={control}
                      options={optionsWithPrefectures}
                      disabled={isConfirm}
                      onChange={prefectureSelectChange}
                      showError={false}
                      menuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // ここで最大高さを設定
                          },
                        },
                      }}
                    />
                  </div>
                  <div className="select-school-city">
                    <RHFSelectMunicipality
                      name="municipalities_name"
                      label="市区町村"
                      control={control}
                      options={optionWithMunicipalities}
                      disabled={isConfirm}
                      showError={false}
                      onChange={manicipalitySelectChange}
                      menuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // ここで最大高さを設定
                          },
                        },
                      }}
                    />
                  </div>
                  <div className="select-school">
                    <RHFSelectSchool
                      name="school_name"
                      label="選択してください"
                      control={control}
                      options={optionWithSchoolNames}
                      disabled={isConfirm}
                      showError={false}
                      onChange={selectChange}
                      menuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // ここで最大高さを設定
                          },
                        },
                      }}
                    />
                  </div>
                  <div className="input-school-alt">
                    <RHFTextField
                      name="school_alt"
                      control={control}
                      placeholder="選択肢にないため自由入力する"
                      disabled={isConfirm || isSchoolAltDisabled}
                      showError={false}
                    />
                  </div>
                </div>
                <div className="div-28">
                  <div className="text-wrapper-349">学校名</div>
                  <div className="group-219">
                    <div className="overlap-group-64">
                      <div className="text-wrapper-355">必須</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="block-person">
                <div className="div-29">
                  <RHFTextField
                    name="person_name"
                    control={control}
                    disabled={isConfirm}
                    showError={false}
                  />
                </div>
                <div className="div-30">
                  <div className="text-wrapper-349">担当者名</div>
                  <div className="group-219">
                    <div className="overlap-group-64">
                      <div className="text-wrapper-355">必須</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="block-tel">
                <div className="input-tel">
                  <RHFTextField
                    name="tel"
                    control={control}
                    placeholder="000-0000-0000"
                    type="tel"
                    disabled={isConfirm}
                    showError={false}
                  />
                </div>
                <div className="label-tel-2">電話番号</div>
              </div>

              <div className="block-email">
                <div className="div-29">
                  <RHFTextField
                    name="mail_address"
                    control={control}
                    type="email"
                    disabled={isConfirm}
                    showError={false}
                  />
                </div>
                <div className="label-email-2">
                  <div className="text-wrapper-354">メールアドレス</div>
                  <div className="group-218">
                    <div className="text-wrapper-351">必須</div>
                  </div>
                </div>
              </div>
              <div className="block-email-confirm">
                <div className="div-29">
                  <RHFTextField
                    name="emailConfirm"
                    control={control}
                    type="email"
                    disabled={isConfirm}
                    showError={false}
                  />
                </div>
                <div className="label-email-confirm-2">
                  <div className="text-wrapper-360">
                    ＊私用のメールアドレスは入力しないでください。
                  </div>
                  <div className="text-wrapper-354">メールアドレス（確認）</div>
                  <div className="group-218">
                    <div className="text-wrapper-351">必須</div>
                  </div>
                </div>
              </div>
              <div className="block-store">
                <div className="div-29">
                  <RHFTextField
                    name="distributor_name"
                    control={control}
                    disabled={isConfirm}
                    showError={false}
                  />
                </div>
                <div className="div-30">
                  <div className="text-wrapper-3491">販売店名</div>
                  <div className="group-218-2">
                    <div className="text-wrapper-351">必須</div>
                  </div>
                </div>
              </div>
              <div className="block-textbook" style={{position:'relative'}}>
                <div className="text-wrapper-360" style={{top:'-24px'}}>
                  ＊教師分も含めた数を入力してください。
                </div>
                <div className="div-textbook1">
                  <div className="select-textbook-4">
                    <RHFSelect
                      name="textbooks1"
                      label="選択してください"
                      control={control}
                      options={options}
                      disabled={isConfirm}
                      setValue={setValue}
                      showError={false}
                      menuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // ここで最大高さを設定
                          },
                        },
                      }}
                    />
                  </div>
                  <div className="input-textbook-count-2">
                    <div className="div-282">
                      <div className="group-2183">
                        <div className="text-wrapper-351">必須</div>
                      </div>
                      <div className="text-wrapper-349">購入した数</div>
                    </div>
                    <div className="group-216">
                      <RHFTextField
                        name="textbookQty1"
                        control={control}
                        placeholder="半角数字"
                        type="number"
                        disabled={isConfirm}
                        showError={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="div-textbook2">
                  <div className="select-textbook-4">
                    <RHFSelect
                      name="textbooks2"
                      label="選択してください"
                      control={control}
                      options={options}
                      disabled={isConfirm}
                      setValue={setValue}
                      showError={false}
                      menuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // ここで最大高さを設定
                          },
                        },
                      }}
                    />
                  </div>
                  <div className="input-textbook-count-2">
                    <div className="div-282">
                      <div className="text-wrapper-349">購入した数</div>
                    </div>
                    <div className="group-216">
                      <RHFTextField
                        name="textbookQty2"
                        control={control}
                        placeholder="半角数字"
                        type="number"
                        disabled={isConfirm}
                        showError={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="div-textbook3">
                  <div className="select-textbook-4">
                    <RHFSelect
                      name="textbooks3"
                      label="選択してください"
                      control={control}
                      options={options}
                      disabled={isConfirm}
                      setValue={setValue}
                      showError={false}
                      menuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // ここで最大高さを設定
                          },
                        },
                      }}
                    />
                  </div>
                  <div className="input-textbook-count-2">
                    <div className="div-282">
                      <div className="text-wrapper-349">購入した数</div>
                    </div>
                    <div className="group-216">
                      <RHFTextField
                        name="textbookQty3"
                        control={control}
                        placeholder="半角数字"
                        type="number"
                        disabled={isConfirm}
                        showError={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="div-textbook4">
                  <div className="select-textbook-4">
                    <RHFSelect
                      name="textbooks4"
                      label="選択してください"
                      control={control}
                      options={options}
                      disabled={isConfirm}
                      setValue={setValue}
                      showError={false}
                      menuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // ここで最大高さを設定
                          },
                        },
                      }}
                    />
                  </div>
                  <div className="input-textbook-count-2">
                    <div className="div-282">
                      <div className="text-wrapper-349">購入した数</div>
                    </div>
                    <div className="group-216">
                      <RHFTextField
                        name="textbookQty4"
                        control={control}
                        placeholder="半角数字"
                        type="number"
                        disabled={isConfirm}
                        showError={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="div-textbook5">
                  <div className="select-textbook-4">
                    <RHFSelect
                      name="textbooks5"
                      label="選択してください"
                      control={control}
                      options={options}
                      disabled={isConfirm}
                      setValue={setValue}
                      showError={false}
                      menuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // ここで最大高さを設定
                          },
                        },
                      }}
                    />
                  </div>
                  <div className="input-textbook-count-2">
                    <div className="div-282">
                      <div className="text-wrapper-349">購入した数</div>
                    </div>
                    <div className="group-216">
                      <RHFTextField
                        name="textbookQty5"
                        control={control}
                        placeholder="半角数字"
                        type="number"
                        disabled={isConfirm}
                        showError={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="div-textbook6">
                  <div className="select-textbook-4">
                    <RHFSelect
                      name="textbooks6"
                      label="選択してください"
                      control={control}
                      options={options}
                      disabled={isConfirm}
                      setValue={setValue}
                      showError={false}
                      menuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 250, // ここで最大高さを設定
                          },
                        },
                      }}
                    />
                  </div>
                  <div className="input-textbook-count-2">
                    <div className="div-282">
                      <div className="text-wrapper-349">購入した数</div>
                    </div>
                    <div className="group-216">
                      <RHFTextField
                        name="textbookQty6"
                        control={control}
                        placeholder="半角数字"
                        type="number"
                        disabled={isConfirm}
                        showError={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="label-textbook-2">
                  <div className="group-218">
                    <div className="text-wrapper-351">必須</div>
                  </div>
                  <div className="text-wrapper-347">購入教材名</div>
                  <div className="text-wrapper-3484">購入教材名</div>
                  <div className="text-wrapper-3493">購入教材名</div>
                  <div className="text-wrapper-352">購入教材名</div>
                  <div className="text-wrapper-353">購入教材名</div>
                  <div className="text-wrapper-3533">購入教材名</div>
                </div>
              </div>
              <div className="block-memo">
                <div className="group-208">
                  <div className="text-wrapper-348">備考</div>
                </div>
                <div className="group-209">
                  <RHFTextField
                    name="memo"
                    control={control}
                    multiline={true}
                    rows={7}
                    disabled={isConfirm}
                    showError={false}
                  />
                </div>
              </div>
              <div className="submit-button">
                {!isConfirm ? (
                  <>
                    <MainButton className="main-button-9" type="submit">
                      <div className="text-wrapper-3472">確認画面へ</div>
                    </MainButton>
                  </>
                ) : (
                  <>
                    <SubButton
                      className="sub-button-3"
                      type="button"
                      onClick={handleEdit}
                    >
                      <div className="text-wrapper-3472">修正する</div>
                    </SubButton>
                    <MainButton
                      className="main-button-8"
                      type="button"
                      onClick={handleMailSendPost}
                    >
                      <div className="text-wrapper-3472">申請する</div>
                    </MainButton>
                  </>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
function useEffect(arg0: () => void, arg1: never[]) {
  throw new Error('Function not implemented.');
}

