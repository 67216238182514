import { KanjiDictionaryLearningHistory } from '@/src/__generated__';
import { formatToDateTimeDisplayLong, formatDuration } from '@/src/helpers/date';
import { useAuthContext } from '@/src/middleware/auth/AuthContext';
import { ColumnDef } from '@tanstack/react-table';
import { Cell } from 'recharts';

export type RowData = KanjiDictionaryLearningHistory;
export const COLUMN_DEFS: Record<string, ColumnDef<RowData>> = {
  grade: {
    id: 'grade',
    accessorKey: 'school_class.grade',
    size: 60,
    header: '年',
    cell: (cell) => `${cell.getValue()}年`,
  },
  class: {
    id: 'class',
    accessorKey: 'school_class.class_name',
    size: 60,
    header: '組',
    cell: (cell) => `${cell.getValue()}組`,
  },
  name: {
    id: 'name',
    accessorKey: 'user_uuid',
    size: 220,
    meta: { align: 'left' },
    header: '氏名',
    cell: (cell) => {
      const { getUserFromUuid } = useAuthContext();
      const uuid = cell.getValue() as string | undefined;
      const user = uuid && getUserFromUuid?.(uuid);
      if (!user) return '';
      return `${user.user_name} さん`;
    },
  },
  last_action_date: {
    id: 'last_action_date',
    accessorKey: 'last_action_date',
    size: 237,
    header: ()=>(<div>最後に辞典を<br/>使った日時</div>),
    cell: (cell) => {
      return cell.getValue() ? formatToDateTimeDisplayLong(
        cell.getValue() as Date
      ) : "-";
    },
  },
  active_time: {
    id: 'active_time',
    accessorKey: 'active_time',
    size: 112,
    header: ()=>(<div>辞典を<br/>使った時間</div>),
    cell: (cell) => {
      return cell.getValue() ? formatDuration(cell.getValue() as number || 0) : "-";
    }
  },
  total_number_word: {
    id: 'total_number_word',
    accessorKey: 'total_number_word',
    size: 130,
    header: ()=>(<div>調べた漢字や<br/>言葉の数</div>),
    meta: { align: 'right' },
  },
  total_number_labels: {
    id: 'total_number_labels',
    accessorKey: 'total_number_labels',
    size: 130,
    header: ()=>(<div>ふせんを<br/>つけた数</div>),
    meta: { align: 'right' },
  },
  total_number_word_quiz: {
    id: 'total_number_word_quiz',
    accessorKey: 'total_number_word_quiz',
    size: 130,
    header: ()=>(<div>ことばクイズ<br/>に取り組んだ<br/>回数</div>),
    meta: { align: 'right' },
  },
  total_number_correct: {
    id: 'total_number_correct',
    accessorKey: 'total_number_correct',
    size: 130,
    header: ()=>(<div>ことばクイズ<br/>に正解した<br/>回数</div>),
    meta: { align: 'right' },
  },
  total_number_medals: {
    id: 'total_number_medals',
    accessorKey: 'total_number_medals',
    size: 130,
    header: 'メダルの数',
    meta: { align: 'right' },
  },
};

export const COLUMNS = [
  COLUMN_DEFS.grade,
  COLUMN_DEFS.class,
  COLUMN_DEFS.name,
  COLUMN_DEFS.last_action_date,
  COLUMN_DEFS.active_time,
  COLUMN_DEFS.total_number_word,
  COLUMN_DEFS.total_number_labels,
  COLUMN_DEFS.total_number_word_quiz,
  COLUMN_DEFS.total_number_correct,
  COLUMN_DEFS.total_number_medals,
];