import { KanjiDictionaryLearningHistory } from '@/src/__generated__';
import {
  formatDuration,
  formatToDateTimeDisplayLong,
} from '@/src/helpers/date';
import { ColumnDef } from '@tanstack/react-table';

type Columns =
  | 'last_action_date'
  | 'active_time'
  | 'total_number_word'
  | 'total_number_labels'
  | 'total_number_word_quiz'
  | 'total_number_correct'
  | 'total_number_medals';

export type RowData = KanjiDictionaryLearningHistory;
export const COLUMN_DEFS: Record<Columns, ColumnDef<RowData>> = {
  last_action_date: {
    id: 'last_action_date',
    size: 249,
    header: () => (
      <div>
        <div>
          <ruby>
            最<rt>さい</rt>
          </ruby>
          <ruby>
            後<rt>ご</rt>
          </ruby>
          に
          <ruby>
            辞<rt>じ</rt>
          </ruby>
          <ruby>
            典<rt>てん</rt>
          </ruby>
          を
        </div>
        <div>
          <ruby>
            使<rt>つか</rt>
          </ruby>
          った
          <ruby>
            日<rt>にち</rt>
          </ruby>
          <ruby>
            時<rt>じ</rt>
          </ruby>
        </div>
      </div>
    ),
    cell: (cell) =>
      (cell.row.original.last_action_date &&
        formatToDateTimeDisplayLong(cell.row.original.last_action_date)) ||
      '',
  },
  active_time: {
    accessorKey: 'active_time',
    size: 200,
    header: () => (
      <div>
        <div>
          <ruby>
            辞<rt>じ</rt>
          </ruby>
          <ruby>
            典<rt>てん</rt>
          </ruby>
          を
          <ruby>
            使<rt>つか</rt>
          </ruby>
          った
        </div>
        <div>
          <ruby>
            時<rt>じ</rt>
          </ruby>
          <ruby>
            間<rt>かん</rt>
          </ruby>
        </div>
      </div>
    ),
    cell: (cell) => formatDuration(cell.row.original.active_time || 0),
  },
  total_number_word: {
    accessorKey: 'total_number_word',
    size: 223,
    meta: { align: 'right' },
    header: () => (
      <div>
        <div>
          <ruby>
            調<rt>しら</rt>
          </ruby>
          べた
        </div>
        <div>
          <ruby>
            漢字<rt>かんじ</rt>
          </ruby>
          や
          <ruby>
            言<rt>こと</rt>
          </ruby>
          <ruby>
            葉<rt>ば</rt>
          </ruby>
          の
          <ruby>
            数<rt>かず</rt>
          </ruby>
        </div>
      </div>
    ),
  },
  total_number_labels: {
    accessorKey: 'total_number_labels',
    size: 223,
    meta: { align: 'right' },
    header: () => (
      <div>
        <div>ふせんを</div>
        <div>
          つけた
          <ruby>
            数<rt>かず</rt>
          </ruby>
        </div>
      </div>
    ),
  },
  total_number_word_quiz: {
    accessorKey: 'total_number_word_quiz',
    size: 223,
    meta: { align: 'right' },
    header: () => (
      <div>
        <div>ことばクイズに</div>
        <div>
          <ruby>
            取<rt>と</rt>
          </ruby>
          り
          <ruby>
            組<rt>く</rt>
          </ruby>
          んだ
          <ruby>
            回<rt>かい</rt>
          </ruby>
          <ruby>
            数<rt>すう</rt>
          </ruby>
        </div>
      </div>
    ),
  },
  total_number_correct: {
    accessorKey: 'total_number_correct',
    size: 223,
    meta: { align: 'right' },
    header: () => (
      <div>
        <div>ことばクイズに</div>
        <div>
          <ruby>
            正<rt>せい</rt>
          </ruby>
          <ruby>
            解<rt>かい</rt>
          </ruby>
          した
          <ruby>
            回<rt>かい</rt>
          </ruby>
          <ruby>
            数<rt>すう</rt>
          </ruby>
        </div>
      </div>
    ),
  },
  total_number_medals: {
    accessorKey: 'total_number_medals',
    size: 222,
    meta: { align: 'right' },
    header: () => (
      <div>
        メダルの
        <ruby>
          数<rt>かず</rt>
        </ruby>
      </div>
    ),
  },
};
