import React, { useEffect, useState } from "react";
import "./style.css";
import { useLocation, useNavigate } from "react-router-dom";
import SubButton from "@/src/components/parts/SubButton";
import Kanji from "@/src/components/auth/Kanji";

export const Error = (): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState<string | JSX.Element>('');
  const [showButton, setShowButton] = useState<boolean>(false);
  const location = useLocation();
  useEffect(() => {
    const _message =
      'state' in location && location.state && 'errorMessage' in location.state
        ? <div dangerouslySetInnerHTML={{ __html: location.state.errorMessage || '' }} />
        : <>エラーが<Kanji kana="お">起</Kanji>きました<br />
          あとでもう<Kanji kana="いち">一</Kanji><Kanji kana="ど">度</Kanji>ためしてください
        </>;
    setErrorMessage(_message);
    if ('state' in location && location.state && 'showButton' in location.state) {
      if (location.state.showButton) {
        setShowButton(true);
      }
    }
  }, []);
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/");
  };
  return (
    <div className="error">
      <div className="overlap">
        <div className="div">
          <img className="dots" alt="Dots" src="/img/dots-4.png" />
          <img className="img" alt="Dots" src="/img/dots-4.png" />
          <img className="dots-2" alt="Dots" src="/img/dots-4.png" />
          <img className="dots-3" alt="Dots" src="/img/dots-1.png" />
        </div>
        <div className="view">
          <div className="overlap-group">
            <div className="main-header">
              {errorMessage}
            </div>
          </div>
        </div>
        {showButton ? (
          <div className="button-container">
            <SubButton className='sub-button-back-2' onClick={goBack}>
              <div className="sub-button-text">Topページへ</div>
            </SubButton>
          </div>
        ) : (
          <div className="returnmsg">ブラウザのとじる [×] ボタンをおしてウィンドウをとじてください</div>
        )}

      </div>
    </div>
  );
};
