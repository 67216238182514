import Button, { ButtonProps } from '@mui/material/Button';
import clsx from 'clsx';
import './style.css';

export type AgreeButtonProps = ButtonProps & {};

const AgreeButton = ({ className, children, ...props }: AgreeButtonProps) => {
  return (
    <Button className={clsx(className, 'agree-button')} {...props}>
      {children}
    </Button>
  );
};

export default AgreeButton;
